<template>
  <div class="slider-widget">
    <Swiper
      v-if="widget.items"
      ref="swiperComponent"
      effect="fade"
      :slides-per-view="1"
      :space-between="0"
      :navigation="
        widget.items.length > 1
          ? {
              prevEl: `#widget-${widget.id} .navigation-button--prev`,
              nextEl: `#widget-${widget.id} .navigation-button--next`
            }
          : false
      "
      :autoplay="
        widget.items.length > 1
          ? { delay: 7000, disableOnInteraction: false }
          : false
      "
      :loop="widget.items.length > 1"
      auto-height
      :allow-touch-move="!isUsingMouse"
      class="slider"
      :class="[
        {
          'slider--without-controls': widget.items.length === 1
        }
      ]"
      @slide-change="handleSlideChange"
    >
      <template v-if="widget.items.length > 1" #navigation>
        <div class="navigation-wrap grid">
          <div class="navigation">
            <button
              :aria-label="t('slider.previous')"
              class="navigation-button navigation-button--prev"
            >
              <ArrowRight class="icon" aria-hidden="true" role="img" />
            </button>
            <button
              :aria-label="t(`slider.${isPlaying ? 'pause' : 'play'}`)"
              class="navigation-button navigation-button--play-pause"
              @click="onPlayPause"
            >
              <PauseIcon
                v-if="isPlaying"
                class="icon"
                aria-hidden="true"
                role="img"
              />
              <PlayIcon v-else class="icon" aria-hidden="true" role="img" />
            </button>
            <button
              :aria-label="t('slider.next')"
              class="navigation-button navigation-button--next"
            >
              <ArrowRight class="icon" aria-hidden="true" role="img" />
            </button>
          </div>
        </div>
      </template>

      <SwiperSlide
        v-for="(slide, index) in items"
        :key="index"
        class="slide"
        :swiper-slide-index="index"
      >
        <Component
          :is="
            slide.link ? BaseLink : items.length === 1 ? 'section' : 'article'
          "
          :link="slide.link"
          class="slide-content grid"
          :class="{ 'slide-content--link': slide.link }"
        >
          <WidgetHeading
            v-viewport-animated
            :is-first="isFirst && index == 0"
            :content="slide.title"
            :content-sup="slide.artist_country"
            class="title"
            :is-big="isFirst"
          >
            <template #right>
              <span v-html="slide.text" />
            </template>
          </WidgetHeading>
          <div
            v-viewport-animated
            class="media grid"
            :class="{ 'media--multi-slide': items.length > 1 }"
          >
            <ClientOnly>
              <OutlineText
                v-if="slide.background_text"
                :text="slide.background_text"
                class="background-text"
                :style="{
                  '--rotate': slide.vars.rotate,
                  '--x': slide.vars.x
                }"
              />
            </ClientOnly>
            <BaseImage
              :image="slide.image"
              :img-sizes="[
                { w: 335, h: 335 },
                { w: 704, h: 422 },
                { w: 785, h: 470 }
              ]"
              sizes="(min-width: 753px) 49vw, 100vw"
              fit="fill"
              :width="785"
              :height="470"
              :lazyload="lazyImage || index > 0"
            />
          </div>
        </Component>
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<script lang="ts" setup>
import { useMediaQuery } from '@vueuse/core'
import { Swiper as SwiperClass } from 'swiper/types'

import ArrowRight from '@/assets/icons/arrow-right.svg?component'
import PauseIcon from '@/assets/icons/pause-btn.svg?component'
import PlayIcon from '@/assets/icons/play-btn.svg?component'
import OutlineText from '@/components/OutlineText.vue'
import SwiperSlide from '@/components/Swiper/Slide.vue'
import Swiper from '@/components/Swiper/index.vue'
import { PublicSliderWidget } from '@/service/__generated-api'
import { Widget } from '@/types/utils'
import { getRandomNumber } from '@/utils'

import 'swiper/css'

const props = defineProps<Widget<PublicSliderWidget>>()
const isUsingMouse = useMediaQuery('(hover: hover)')

const BaseLink = resolveComponent('BaseLink')

const { t } = useI18n()
const swiperComponent = ref<InstanceType<typeof Swiper>>()
const activeIndex = ref(0)
const handleSlideChange = (s: SwiperClass) => {
  activeIndex.value = s.realIndex
}

const items = computed(() =>
  props.widget.items.map(item => ({
    ...item,
    vars: {
      rotate: `${getRandomNumber(-16, 16)}deg`,
      x: `${getRandomNumber(-15, -5)}%`
    }
  }))
)

const isPlaying = ref(true)

const onPlayPause = () => {
  isPlaying.value = !isPlaying.value
  swiperComponent.value?.swiper?.autoplay?.[
    isPlaying.value ? 'start' : 'stop'
  ]()
}

watch(
  () => props.widget,
  async () => {
    await nextTick()
    swiperComponent.value?.swiper?.update?.()
  }
)
</script>

<style lang="scss" scoped>
.slider-widget {
  position: relative;
  touch-action: pan-y;
}

.slider {
  overflow: visible;
}

.swiper-slide {
  overflow-x: clip;
  overflow-y: visible;
}

.slide:not(.swiper-slide-active) {
  pointer-events: none;
}

.slide-content {
  width: 100%;

  &--link {
    @include focus-visible(-2px);
  }
}

.slider {
  &--without-controls:deep(.controls) {
    display: none !important;
  }

  &:deep(.controls) {
    opacity: 0;
  }
}

.swiper-initialized:deep(.controls) {
  opacity: 1;
}

:deep(.swiper-wrapper) {
  @include media-down(lg) {
    order: -1;
  }
}

.navigation-wrap {
  @include media-up(lg) {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    pointer-events: none;
  }
}

.navigation {
  @include media-column(
    (
      xs: 4,
      md: 8,
      lg: 12
    )
  );
  display: flex;
  gap: rem(4px);
  justify-self: end;
  margin-top: rem(24px);

  @include media-up(md) {
    margin-top: rem(32px);
  }

  @include media-up(lg) {
    justify-self: start;
    margin-top: 0;
  }
}

.navigation-button {
  @include focus-visible;
  @include center-content;
  pointer-events: all;

  .icon {
    @include size(rem(44px));

    @include media-up(md) {
      @include size(rem(52px));
    }
  }

  &--prev .icon {
    transform: rotate(180deg);
  }
}

.title {
  @include media-column(
    (
      xs: 4,
      md: 8,
      lg: 12
    )
  );
}

.media {
  @include media-column(
    (
      xs: all,
      md: all,
      lg: all
    )
  );
  position: relative;
  padding-top: rem(16px);

  @include media-up(lg) {
    padding-top: rem(24px);
  }

  @include media-up(xxl) {
    padding-top: rem(32px);
  }
}

.background-text {
  position: absolute;
  top: 120%;
  left: 0;
  width: 70%;
  max-height: rem(186px);
  transform: translate(var(--x, 0), -50%) rotate(var(--rotate, 0deg));
  transform-origin: 50% 50%;

  @include media-up(md) {
    width: 53%;
    max-height: rem(205px);
  }

  @include media-up(lg) {
    top: 50%;
    width: 70%;
    max-height: rem(408px);
  }
}

.image-wrapper {
  @include media-column(
    (
      xs: 4,
      md: 8,
      lg: 7 5
    )
  );
  @include aspect-ratio(1, 1);

  @include media-up(md) {
    @include aspect-ratio(704, 422);
  }

  @include media-up(lg) {
    @include aspect-ratio(785, 470);
  }
}

.media:deep(.image) {
  @include fill-space;
  opacity: 0;
  transition: opacity 1s $dynamic-timing-function;

  .swiper-slide-active & {
    opacity: 1;
  }
}
</style>
